<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <router-link :to="{ name: 'Logs' }">
          <v-icon class="cursor--pointer">mdi-reply</v-icon>
        </router-link>
        {{ $t("Log Details") }}
      </div>
    </div>
    <v-alert v-model="errorAlert" dismissible dense outlined type="error">
      <span> {{ errorMsg }} </span>
    </v-alert>
    <v-card>
      <v-container>
        <template v-if="loading">
          <div class="d-flex justify-center my-4">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <v-row>
          <v-col><strong> Uuid: </strong> {{ log ? log.uuid : "" }} </v-col>
          <v-col
            ><strong> Date: </strong>
            {{ log ? prettyDate(log.createAt) : "" }}</v-col
          >
          <v-col><strong> Path: </strong> {{ log ? log.path : "" }}</v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row>
          <v-col
            ><strong> Type: </strong>
            <span :class="`${colortype}--text`">{{ log ? log.type : "" }}</span>
          </v-col>
          <v-col><strong> Method: </strong> {{ log ? log.method : "" }} </v-col>
          <v-col
            ><strong> Description: </strong> {{ log ? log.description : "" }}
          </v-col>
        </v-row>

        <template v-if="createdBy">
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col
              ><strong> Name: </strong> {{ log ? log.createdBy.fullname : "" }}
            </v-col>
            <v-col
              ><strong> Email: </strong> {{ log ? log.createdBy.email : "" }}
            </v-col>
            <v-col
              ><strong> Rol: </strong> {{ log ? log.createdBy.rol : "" }}
            </v-col>
          </v-row>
        </template>

        <template v-if="log != null && haveQuerys">
          <v-divider class="my-3"></v-divider>
          <div class="text-center mt-2 mb-3"><strong>Queries:</strong></div>
          <v-row>
            <template v-if="typeof log.queries === 'string'">
              <v-col cols="12" sm="12">
                <span>{{ log.queries }}</span>
              </v-col>
            </template>
            <template v-else
              ><v-col
                cols="12"
                sm="4"
                v-for="(lq, i) in dataShow(log.queries)"
                :key="i"
              >
                <strong>{{ lq[0] }}:</strong><span>{{ lq[1] }}</span>
              </v-col></template
            >
          </v-row>
        </template>
        <template v-if="log != null && haveParams">
          <v-divider class="my-3"></v-divider>
          <div class="text-center mt-2 mb-3"><strong>Params:</strong></div>
          <v-row>
            <template v-if="typeof log.params === 'string'">
              <v-col cols="12" sm="12">
                <span>{{ log.params }}</span>
              </v-col>
            </template>
            <template v-else
              ><v-col
                cols="12"
                sm="4"
                v-for="(lpa, i) in dataShow(log.params)"
                :key="i"
              >
                <strong>{{ lpa[0] }}:</strong><span>{{ lpa[1] }}</span>
              </v-col></template
            >
          </v-row>
        </template>
        <template v-if="log != null && haveHeaders">
          <v-divider class="my-3"></v-divider>
          <div class="text-center mt-2 mb-3"><strong>Headers:</strong></div>
          <v-row>
            <template v-if="typeof log.headers === 'string'">
              <v-col cols="12" sm="12">
                <span>{{ log.headers }}</span>
              </v-col>
            </template>
            <template v-else>
              <v-col
                cols="12"
                sm="4"
                v-for="(lh, i) in dataShow(log.headers)"
                :key="i"
              >
                <strong>{{ lh[0] }}:</strong><span>{{ lh[1] }}</span>
              </v-col></template
            >
          </v-row>
        </template>
        <template v-if="log != null && haveBody">
          <v-divider class="my-3"></v-divider>
          <div class="text-center mt-2 mb-3"><strong>Body:</strong></div>

          <v-row>
            <template v-if="typeof log.body === 'string'">
              <v-col cols="12" sm="12">
                <span>{{ log.body }}</span>
              </v-col>
            </template>
            <template v-else>
              <v-col
                cols="12"
                sm="4"
                v-for="(lb, i) in dataShow(log.body)"
                :key="i"
              >
                <strong>{{ lb[0] }}:</strong><span>{{ lb[1] }}</span>
              </v-col></template
            >
          </v-row>
        </template>
        <template
          v-if="log != null && log.procedure != {} && log.procedure != null"
        >
          <v-divider class="my-3"></v-divider>
          <div class="text-center mt-2 mb-3"><strong>Procedure:</strong></div>

          <v-row>
            <template v-if="typeof log.procedure === 'string'">
              <v-col cols="12" sm="12">
                <span>{{ log.procedure }}</span>
              </v-col>
            </template>
            <template v-else>
              <v-col
                cols="12"
                sm="4"
                v-for="(lp, i) in dataShow(log.procedure)"
                :key="i"
              >
                <strong>{{ lp[0] }}:</strong><span>{{ lp[1] }}</span>
              </v-col>
            </template>
          </v-row>
        </template>
        <template v-if="haveResponse">
          <v-divider class="my-3"></v-divider>
          <div class="text-center mt-2 mb-3"><strong>Response:</strong></div>
          <v-row>
            <template v-if="typeof log.response === 'string'">
              <v-col cols="12" sm="12">
                <span>{{ log.response }}</span>
              </v-col>
            </template>
            <template v-else>
              <v-col
                cols="12"
                sm="4"
                v-for="(lr, i) in dataShow(log.response)"
                :key="i"
              >
                <strong>{{ lr[0] }}:</strong><span>{{ lr[1] }}</span>
              </v-col>
            </template>
          </v-row>
          <v-divider class="my-3"></v-divider>
        </template>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import { isEmpty } from "lodash";
export default {
  name: "view-log",
  data() {
    return {
      loading: false,
      log: null,
      errorAlert: false,
      errorMsg: "",
    };
  },
  computed: {
    colortype() {
      if (this.log == null) {
        return "black";
      }
      if (this.log.type == "ERROR") {
        return "red";
      }
      if (this.log.type == "WARNING") {
        return "orange";
      }

      return "green";
    },
    haveBody() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.body)) {
        return false;
      }
      return true;
    },
    haveHeaders() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.headers)) {
        return false;
      }
      return true;
    },
    createdBy() {
      if (this.log == null) {
        return false;
      }
      if (this.log.createdBy == null) {
        return false;
      }
      if (isEmpty(this.log.createdBy)) {
        return false;
      }
      return true;
    },
    haveParams() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.params)) {
        return false;
      }
      return true;
    },
    haveResponse() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.response)) {
        return false;
      }
      return true;
    },
    haveQuerys() {
      if (this.log == null) {
        return false;
      }
      if (isEmpty(this.log.queries)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    dataShow(data) {
      if (data == null || data == undefined || data == "") {
        return "-";
      }
      const x = Object.entries(data);

      return x;
    },

    prettyDate(date) {
      if (date === null || date === "") return "";
      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    getLog() {
      const uuid = this.$route.params.uuid;
      this.loading = true;
      getAPI
        .get("/sys-logs/" + uuid)
        .then((res) => {
          this.log = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const msg = error.response.data
            ? error.response.data.message.toString()
            : error.message;
          this.errorAlert = true;
          this.errorMsg = msg;
        });
    },
  },
  mounted() {
    this.getLog();
  },
};
</script>
<style lang="scss" scoped></style>
